import React from 'react';
import './OxNiAr.css';
import Menu from '../../../Menu/Menu';
import { Card, CardGroup, Table } from 'react-bootstrap';
import ox1 from "../../../../img/epc/industrial & medical gas/ox1.jpg";
import ox2 from "../../../../img/epc/industrial & medical gas/ox2.jpg";
import ox3 from "../../../../img/epc/industrial & medical gas/ox3.jpg";
import ox4 from "../../../../img/epc/industrial & medical gas/ox4.jpg";
import ox5 from "../../../../img/epc/industrial & medical gas/ox5.jpg";
import ni1 from "../../../../img/epc/industrial & medical gas/ni1.jpg";
import ni2 from "../../../../img/epc/industrial & medical gas/ni2.jpg";
import ni3 from "../../../../img/epc/industrial & medical gas/ni3.jpg";
import ni4 from "../../../../img/epc/industrial & medical gas/ni4.jpg";
import ni5 from "../../../../img/epc/industrial & medical gas/ni5.jpg";
import ni6 from "../../../../img/epc/industrial & medical gas/ni6.jpg";
import ar1 from "../../../../img/epc/industrial & medical gas/ar1.jpg";
import ar2 from "../../../../img/epc/industrial & medical gas/ar2.jpg";
import ar3 from "../../../../img/epc/industrial & medical gas/ar3.jpg";
import ar4 from "../../../../img/epc/industrial & medical gas/ar4.jpg";


const OxNiAr = () => {
    return (
        <div className='mb-5'>
            
            <div className='about5'>
                <div className='bg'>
                    <Menu></Menu>
                    <h1 className='text-white display-5 fw-bold doris pt-lg-5 mt-lg-5 pb-5 mx-auto top'>Industrial & Medical Gas</h1>
                </div>
            </div>
            <div id="ox">
                <h2 className='heading pt-5 mx-5'>EPC Project on Oxygen Plant (PSA & Cryogenic)</h2>
                <hr className='w-75 mx-auto'></hr>
                <h5 className='px-5 mx-lg-3'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-4 col-sm-12 d-flex justify-content-center pt-4'>
                            <ol>
                                <li><strong>PSA oxygen generation</strong><br></br><br></br>
                                    VSA oxygen generation <br></br>
                                    Air separation unit-O2 <br></br><br></br>
                                    Flow:1-5000 Nm3/hr <br></br>
                                    Purity:90-99.9% <br></br>
                                    Pressure:1-300 barg. <br></br><br></br>
                                </li>
                                <li><strong>Cryogenic oxygen generation</strong><br></br><br></br>
                                    VSA oxygen generation <br></br>
                                    Air separation unit-O2 <br></br><br></br>
                                    Flow:1-5000 Nm3/hr <br></br>
                                    Purity:90-99.9% <br></br>
                                    Pressure:1-300 barg. <br></br><br></br>
                                </li>
                            </ol>
                        </div>
                        <div className='col-lg-8 col-md-8 col-sm-12 pt-4'>
                            <div className='row px-lg-5 pe-3'>
                                <CardGroup className='pe-lg-5'>
                                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                                        <Card.Img variant="top" className="rounded-3" src={ox1} height="100%"/>
                                        <Card.ImgOverlay className="text-light overlay">
                                            
                                        </Card.ImgOverlay>
                                    </Card>
                                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                                        <Card.Img variant="top" className="rounded-3" src={ox2} height="100%"/>
                                        <Card.ImgOverlay className="text-light overlay">
                                            
                                        </Card.ImgOverlay>
                                    </Card>
                                </CardGroup>
                            </div>
                            <div className='row ps-lg-5 pe-3'>
                                <CardGroup className='pe-lg-5'>
                                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                                        <Card.Img variant="top" className="rounded-3" src={ox4} height="100%"/>
                                        <Card.ImgOverlay className="text-light overlay">

                                        </Card.ImgOverlay>
                                    </Card>
                                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                                        <Card.Img variant="top" className="rounded-3" src={ox5} height="100%"/>
                                        <Card.ImgOverlay className="text-light overlay">
                                            
                                        </Card.ImgOverlay>
                                    </Card>
                                </CardGroup>
                            </div>
                        </div>
                    </div>
                </h5>
            </div>
            <div id="ni">
                <h2 className='heading pt-5'>EPC Project on Nitrogen Plant</h2>
                <hr className='w-75 mx-auto'></hr>
                <h5 className='px-5 mx-lg-3'>
                    <div className='row'>
                        <div className='col-lg-9 col-md-9 col-sm-12 pt-3'>
                            <div className='row px-5 pe-3 small ms-2'>
                                <CardGroup className='pe-5'>
                                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                                        <Card.Img variant="top" className="rounded-3" src={ni1} height="100%"/>
                                        <Card.ImgOverlay className="text-light overlay">
                                            
                                        </Card.ImgOverlay>
                                    </Card>
                                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                                        <Card.Img variant="top" className="rounded-3" src={ni2} height="100%"/>
                                        <Card.ImgOverlay className="text-light overlay">
                                            
                                        </Card.ImgOverlay>
                                    </Card>
                                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                                        <Card.Img variant="top" className="rounded-3" src={ni3} height="100%"/>
                                        <Card.ImgOverlay className="text-light overlay">

                                        </Card.ImgOverlay>
                                    </Card>
                                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                                        <Card.Img variant="top" className="rounded-3" src={ni4} height="100%"/>
                                        <Card.ImgOverlay className="text-light overlay">
                                            
                                        </Card.ImgOverlay>
                                    </Card>
                                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                                        <Card.Img variant="top" className="rounded-3" src={ni5} height="100%"/>
                                        <Card.ImgOverlay className="text-light overlay">
                                            
                                        </Card.ImgOverlay>
                                    </Card>
                                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                                        <Card.Img variant="top" className="rounded-3" src={ni6} height="100%"/>
                                        <Card.ImgOverlay className="text-light overlay">

                                        </Card.ImgOverlay>
                                    </Card>
                                </CardGroup>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-3 col-sm-12 pt-4'>
                            <strong>Nitrogen generation</strong><br></br><br></br>
                                    PSA nitrogen generation <br></br>
                                    Membrane nitrogen generation <br></br>
                                    Air separation unit-N2 <br></br><br></br>
                                    Flow:1-5000 Nm3/hr <br></br>
                                    Purity:90-99.999% <br></br>
                                    Pressure:1-300 barg. <br></br><br></br>

                        </div>
                    </div>
                </h5>
            </div>
            <div id="ar">
                <h2 className='heading pt-5'>EPC Project on Argon Plant</h2>
                <hr className='w-75 mx-auto'></hr>
                <h5 className='px-5 mx-lg-3'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-4 col-sm-12 d-flex justify-content-center pt-5'>
                            <div>
                            <strong>Argon generation</strong><br></br><br></br>
                                    Flow:1-5000 Nm3/hr <br></br>
                                    Purity:90-99.999% <br></br>
                                    Pressure:1-300 barg. <br></br><br></br>
                            </div>
                        </div>
                        <div className='col-lg-8 col-md-8 col-sm-12'>
                            <div className='row ps-lg-5 pe-3'>
                                <CardGroup className='pe-lg-5'>
                                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                                        <Card.Img variant="top" className="rounded-3" src={ar1} height="100%"/>
                                        <Card.ImgOverlay className="text-light overlay">
                                            
                                        </Card.ImgOverlay>
                                    </Card>
                                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                                        <Card.Img variant="top" className="rounded-3" src={ar2} height="100%"/>
                                        <Card.ImgOverlay className="text-light overlay">
                                            
                                        </Card.ImgOverlay>
                                    </Card>
                                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                                        <Card.Img variant="top" className="rounded-3" src={ox3} height="100%"/>
                                        <Card.ImgOverlay className="text-light overlay">

                                        </Card.ImgOverlay>
                                    </Card>
                                </CardGroup>
                                <div className='row pe-lg-3'>
                                    <CardGroup className=''>
                                        <Card className="mx-3 mb-5 bg-transparent border-0 service">
                                            <Card.Img variant="top" className="rounded-3" src={ar3} height="100%"/>
                                            <Card.ImgOverlay className="text-light overlay">

                                            </Card.ImgOverlay>
                                        </Card>
                                        <Card className="mx-3 mb-5 bg-transparent border-0 service">
                                            <Card.Img variant="top" className="rounded-3" src={ar4} height="100%"/>
                                            <Card.ImgOverlay className="text-light overlay">
                                                
                                            </Card.ImgOverlay>
                                        </Card>
                                    </CardGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                </h5>
            </div>
            
            
            
        </div>
    );
};

export default OxNiAr;