import AuthProvider from './context/AuthProvider';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './App.css';
import Home from './Components/Home/Home';
import About from './About/About';
import ERW from './Components/Procurement&SupplyChain/LinePipe/WeldedPipe&Tube/ERW/ERW';
import Footer from './Components/Footer/Footer';
import CPTR from './Components/Products/CPTR/CPTR';
import Inverter from './Components/Products/Inverter/Inverter';
import OxNiAr from './Components/EPC/Industrial & Medical Gas/Ox,Ni,Ar/OxNiAr';
import Station from './Components/EPC/Oil & Gas/Station/Station';

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <BrowserRouter>
          <Switch>
            <Route exact path="/">
              <Home></Home>
            </Route>
            <Route path="/home">
              <Home></Home>
            </Route>
            <Route path="/about">
              <About></About>
            </Route>
            <Route path="/erw">
              <ERW></ERW>
            </Route>
            <Route path="/cptr">
              <CPTR></CPTR>
            </Route>
            <Route path="/inverter">
                <Inverter></Inverter>
            </Route>
            <Route path="/oxniar">
                <OxNiAr></OxNiAr>
            </Route>
            <Route path="/station">
                <Station></Station>
            </Route>
          </Switch>
          <Footer></Footer>
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
