import React from 'react';
import './Footer.css';
import logo from "../../img/logo.png";
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPhone, faEnvelope, faMapMarked} from '@fortawesome/free-solid-svg-icons'
import Iframe from 'react-iframe';
import { Card, CardGroup } from 'react-bootstrap';
import bp1 from '../../img/Client logos/ACL-NLogo.png';
import bp2 from '../../img/Client logos/AIL-NLogo.png';
import bp3 from '../../img/Client logos/ati.png';
import bp4 from '../../img/Client logos/blessing.png';
import bp5 from '../../img/Client logos/denimach.png';
import bp6 from '../../img/Client logos/logo-full.png';
import bp7 from '../../img/Client logos/moham.png';
import bp8 from '../../img/Client logos/nice.jpg';
import bp9 from '../../img/Client logos/pakizagroup.png';
import bp10 from '../../img/Client logos/pandora.png';
import bp11 from '../../img/Client logos/rahim_group_logo-01-e1646591491708.png';

const element = <FontAwesomeIcon icon={faPhone} />
const element2 = <FontAwesomeIcon icon={faEnvelope} />
const element3 = <FontAwesomeIcon icon={faMapMarked} />

const Footer = () => {
    return (
        <div className="footer text-center">
            <div className="row">
                {/* first column */}
                <div className="col-md-6 col-lg-4 col-sm-12 text-light py-3">
                    <NavLink to="/home">
                        <img className="w-25 mb-4 d-flex justify-content-start ms-5 mt-4" src={logo} alt="" />
                    </NavLink>
                    <h4 className="fw-bold footer-heading ps-5">Contact</h4>
                    <h5 className="footer-heading ps-5 fw-light">{element} +88 01712 604631<br></br>
                    {element2} info@innocuousenergy.com <br></br>
                    {element3} Innocuous Energy International Ltd.<br></br>
                    House No# 12, Road No# 06,<br></br>
                    Nikunja-2, Khilkhet,
                    Dhaka-1229</h5>
                    <Iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d58388.60298554103!2d90.411181!3d23.843916!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x11e245d769b7e4b9!2sInnocuous%20Energy%20International%20Limited!5e0!3m2!1sen!2sus!4v1651662645718!5m2!1sen!2sus" width="90%" height="245" style={{border:"0"}} allowfullscreen="" loading="lazy" className='ps-5'></Iframe>
                </div>
                {/* second column */}
                <div className="col-md-6 col-lg-4 col-sm-12 text-light p-3 mt-2">
                    <h4 className="footer-heading fw-bold mt-2 pt-2 mb-0 padding-left">Our Valuable Clients</h4>
                    <CardGroup>
                        <Card className="me-3 pe-4 pt-4 mb-0 pb-0 bg-transparent border-0">
                            <Card.Img variant="top" src={bp1} className='service img-footer'/>
                            <Card.Body className="px-0">
                            </Card.Body>
                        </Card>
                        <Card className="me-3 pe-4 pt-4 mb-0 pb-0 bg-transparent border-0">
                            <Card.Img variant="top" src={bp2} className='service img-footer'/>
                            <Card.Body className="px-0">
                            </Card.Body>
                        </Card>
                        <Card className="me-3 pe-4 pt-4 mb-0 pb-0 bg-transparent border-0">
                            <Card.Img variant="top" src={bp7} className='service img-footer'/>
                            <Card.Body className="px-0">
                            </Card.Body>
                        </Card>
                    </CardGroup>
                    <CardGroup>
                        <Card className="me-3 pe-4 pt-4 mb-0 pb-0 bg-transparent border-0">
                            <Card.Img variant="top" src={bp3} className='service img-footer'/>
                            <Card.Body className="px-0">
                            </Card.Body>
                        </Card>
                        <Card className="me-3 pe-4 pt-4 mb-0 pb-0 bg-transparent border-0">
                            <Card.Img variant="top" src={bp6} className='service img-footer'/>
                            <Card.Body className="px-0">
                            </Card.Body>
                        </Card>
                        <Card className="me-3 pe-4 pt-4 mb-0 pb-0 bg-transparent border-0">
                            <Card.Img variant="top" src={bp5} className='service img-footer'/>
                            <Card.Body className="px-0">
                            </Card.Body>
                        </Card>
                    </CardGroup>
                    <CardGroup>
                        <Card className="me-3 pe-4 pt-4 mb-0 pb-0 bg-transparent border-0">
                            <Card.Img variant="top" src={bp4} className='service img-footer'/>
                            <Card.Body className="px-0">
                            </Card.Body>
                        </Card>
                        <Card className="me-3 pe-4 pt-4 mb-0 pb-0 bg-transparent border-0">
                            <Card.Img variant="top" src={bp9} className='service img-footer'/>
                            <Card.Body className="px-0">
                            </Card.Body>
                        </Card>
                        <Card className="me-3 pe-4 pt-4 mb-0 pb-0 bg-transparent border-0">
                            <Card.Img variant="top" src={bp8} className='service img-footer'/>
                            <Card.Body className="px-0">
                            </Card.Body>
                        </Card>
                    </CardGroup>
                    <CardGroup className='w-75'>
                        <Card className="me-3 pe-4 pt-4 mb-0 pb-0 bg-transparent border-0">
                            <Card.Img variant="top" src={bp10} className='service w-75 img-footer'/>
                            <Card.Body className="px-0">
                            </Card.Body>
                        </Card>
                        <Card className="me-3 pe-4 pt-4 mb-0 pb-0 bg-transparent border-0">
                            <Card.Img variant="top" src={bp11} className='service w-75 img-footer'/>
                            <Card.Body className="px-0">
                            </Card.Body>
                        </Card>
                    </CardGroup>
                </div>
                {/* third column */}
                <div className="col-md-6 col-lg-4 col-sm-12 text-light p-3 mt-3 padding-left">
                    <h4 className="footer-heading fw-bold">About</h4>
                    <h5 className="footer-heading2 fw-light pe-5">A leading Company who engages in the provision of energy & power businesses. It operates through the following segments: Liquid Pipelines, Gas Distribution & Storage, Gas Transmission & Midstream, Renewable Power Generation, and Energy Services. The Liquids Pipelines segment consists of common carrier and contract crude oil, natural gas, gas liquids and refined products pipelines and terminals.  We evaluate energy programs and plans so that future performance can be enhanced. We support our clients’ sustainable energy goals through end-to-end strategic, analytical and technical assistance. We assess energy efficiency and renewable energy opportunities and analyze their costs, benefits, and risks. We design leading programs, plans and policies that can realistically meet end-user’s requirements for future energy security and sustainability. The Company is operating its business operations in the international arena specially in USA, Canada, Europe, Turkey, Middle-Est and Asia Pacific regions.</h5>
                </div>
            </div>
            <div className="text-light p-2 h6 mb-0 last bg-dark">
                <p className='m-0'>All rights reserved &copy; 2022 IEIL </p>
                <p className='m-0 text-muted'>Developed by DazingDevs</p>
            </div>
        </div>
    );
};

export default Footer;