import React from 'react';
import Menu from "../Menu/Menu";
import './Header.css'

const Header = () => {
    return (
        <div className='body'>
            <div className='bg1'>
                <Menu></Menu>
                <h2 className='text-white doris top'>Welcome to<br></br>
                <span className='text-white display-5 fw-bold doris my-auto'>Innocuous Energy<br></br> International Limited</span></h2>
            </div>
        </div>
    );
};

export default Header;