import React from 'react';
import './Station.css';
import Menu from '../../../Menu/Menu';
import { Card, CardGroup, Table } from 'react-bootstrap';
import cgs1 from "../../../../img/epc/oil & gas/station/CGS-1.jpg";
import cgs2 from "../../../../img/epc/oil & gas/station/CGS-2.jpg";
import drs1 from "../../../../img/epc/oil & gas/station/DRS-2.jpg";
import rms1 from "../../../../img/epc/oil & gas/station/RMS-6.jpg";
import rms2 from "../../../../img/epc/oil & gas/station/RMS.jpg";
import rms3 from "../../../../img/epc/oil & gas/station/RMS-1.jpg";
import rms4 from "../../../../img/epc/oil & gas/station/RMS-2.jpg";
import rms5 from "../../../../img/epc/oil & gas/station/RMS-3.jpg";
import rms6 from "../../../../img/epc/oil & gas/station/RMS-7.jpg";
import rms7 from "../../../../img/epc/oil & gas/station/RMS-5.jpg";




const Station = () => {
    return (
        <div className='mb-5'>
            
            <div className='about6'>
                <div className='bg'>
                    <Menu></Menu>
                    <h1 className='text-white display-5 fw-bold doris pt-lg-5 mt-lg-5 pb-5 mx-auto top'>Oil & Gas Station</h1>
                </div>
            </div>
            <div id="cgs">
                <h2 className='heading pt-5 mx-3'>EPC Project onsite City Gate Station (CGS)</h2>
                <hr className='w-75 mx-auto'></hr>
                <h5 className='px-5 mx-lg-3'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 pt-3'>
                            A City Gate Station is <strong>a gas measurement and pressure regulating and reducing package</strong> which is installed outside the limits of a city on a gas pipeline. It supplies gas to the city users at required consumption pressure. <br></br> <br></br>

                            City gate stations serve an important role in <strong>the natural gas distribution network.</strong> Consisting of metering and pressure regulating facilities, they are located at the custody transfer points where natural gas is delivered from transmission pipelines into the high-pressure lines of the local distribution company. <br></br> <br></br>

                            CGS is the skid installed on the main cross country line tap-off and feeds the Natural Gas to the City at desired regulated pressure. Typically, CGS is ANSI 600# skid consisting of Filtration, Metering, Pre-Heating, Pressure Regulation and Flow Control units.This is the first step down for the NG Cross Country Line Pressure from 50 – 90 Barg to 19 – 26 Barg to feed NG to cities. <br></br> <br></br>
                            The Pipeline downstream to CGS is ANSI 300# / ANSI 150# after this pressure let down. City Gate Stations are generally equipped with remote control of pressure and flow parameters with wireless communication. The SCADA controls flow and pressure parameters to facilitate the farthest consumer to receive the gas at desired pressure and flow rate.

                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className='row'>
                                <CardGroup className='ps-1 pt-5'>
                                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                                        <Card.Img variant="top" className="rounded-3" src={cgs1} height="100%"/>
                                        <Card.ImgOverlay className="text-light overlay">

                                        </Card.ImgOverlay>
                                    </Card>
                                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                                        <Card.Img variant="top" className="rounded-3" src={cgs2} height="100%"/>
                                        <Card.ImgOverlay className="text-light overlay">

                                        </Card.ImgOverlay>
                                    </Card>
                                </CardGroup>
                            </div>
                        </div>
                    </div>
                </h5>
            </div>
            <div id="drs">
                <h2 className='heading pt-5 px-3'>EPC Project onsite Distribution and Regulating Station (DRS)</h2>
                <hr className='w-75 mx-auto'></hr>
                <h5 className='px-5 mx-lg-3'>
                    <div className='row ps-lg-5'>
                        <div className='col-lg-6 col-md-6 col-sm-12 pt-3 ps-lg-5 mb-3'>
                            Typically, DRS / FRS are ANSI 300# / ANSI 150# skids having Filtration, Pressure Regulation and Metering. DRS/FRS regulates the pressure to 4 – 5 Barg and the further gas distribution network is PE Line instead of Steel Line.
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 px-lg-5'>
                            <div className='row'>
                                <CardGroup className='pe-lg-5'>
                                    <Card className="mx-lg-3 mb-5 bg-transparent border-0 service">
                                        <Card.Img variant="top" className="rounded-3" src={drs1} height="100%"/>
                                        <Card.ImgOverlay className="text-light overlay">

                                        </Card.ImgOverlay>
                                    </Card>
                                </CardGroup>
                            </div>
                        </div>
                    </div>
                </h5>
            </div>
            <div id="rms">
                <h2 className='heading pt-5 px-3'>EPC Project onsite Bulk Customer Regulating and Metering Station (RMS)</h2>
                <hr className='w-75 mx-auto'></hr>
                <h5 className='px-5 mx-lg-3'>
                    <div className='row'>
                        <div className='col-lg-5 col-md-5 col-sm-12 pt-3'>
                        High, Medium and Low Pressure Gas Pressure Drop and Measurement Stations in the production sector in order to adapt to the requirements, demands and specifications of the customer, with its experienced staff, project design, engineering, project management, quality management, material supply, manufacturing, assembly, initial start-up, commissioning and It offers a wide service network to its customers by providing after-sales services. <br></br> <br></br>
 
                        All designs are carried out taking into account the most modern applications. From the beginning to the end of the production and commissioning, many quality controls are passed and reported. Thus, a high level of quality assurance is provided to the customer and significant savings are achieved in labor by simplifying on-site assembly/commissioning.
                        </div>
                        <div className='col-lg-7 col-md-7 col-sm-12'>
                            <div className='row'>
                                <CardGroup className='ps-lg-5 pt-4'>
                                    <Card className="mx-lg-3 mb-5 bg-transparent border-0 service">
                                        <Card.Img variant="top" className="rounded-3" src={rms1} height="100%"/>
                                        <Card.ImgOverlay className="text-light overlay">

                                        </Card.ImgOverlay>
                                    </Card>
                                    <Card className="mx-lg-3 mb-5 bg-transparent border-0 service">
                                        <Card.Img variant="top" className="rounded-3" src={rms2} height="100%"/>
                                        <Card.ImgOverlay className="text-light overlay">

                                        </Card.ImgOverlay>
                                    </Card>
                                </CardGroup>
                            </div>
                        </div>
                    </div>
                    <div className='row pt-3'>
                            <CardGroup className='p-0'>
                                <Card className="mx-3 mb-5 bg-transparent border-0 service">
                                    <Card.Img variant="top" className="rounded-3" src={rms5} height="100%"/>
                                    <Card.ImgOverlay className="text-light overlay">

                                    </Card.ImgOverlay>
                                </Card>
                                <Card className="mx-3 mb-5 bg-transparent border-0 service">
                                    <Card.Img variant="top" className="rounded-3" src={rms6} height="100%"/>
                                    <Card.ImgOverlay className="text-light overlay">

                                    </Card.ImgOverlay>
                                </Card>
                                <Card className="mx-3 mb-5 bg-transparent border-0 service">
                                    <Card.Img variant="top" className="rounded-3" src={rms7} height="100%"/>
                                    <Card.ImgOverlay className="text-light overlay">

                                    </Card.ImgOverlay>
                                </Card>
                                <Card className="mx-3 mb-5 bg-transparent border-0 service">
                                        <Card.Img variant="top" className="rounded-3" src={rms3} height="100%"/>
                                        <Card.ImgOverlay className="text-light overlay">

                                        </Card.ImgOverlay>
                                    </Card>
                                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                                        <Card.Img variant="top" className="rounded-3" src={rms4} height="100%"/>
                                        <Card.ImgOverlay className="text-light overlay">

                                        </Card.ImgOverlay>
                                    </Card>
                            </CardGroup>
                        </div>
                </h5>
            </div>
            
            
            
        </div>
    );
};

export default Station;