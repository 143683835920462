import React from 'react';
import Menu2 from '../Components/Menu2/Menu2';
import './About.css';
import img from '../img/8481.jpg';
import img2 from '../img/Team of engineers and workers controlling oil or gas pipeline.jpg'
import Menu from '../Components/Menu/Menu';
import { Card, CardGroup } from 'react-bootstrap';
import bp1 from '../img/Client logos/pandora.png';
import bp2 from '../img/Client logos/ACL-NLogo.png';
import bp3 from '../img/Client logos/AIL-NLogo.png';
import bp4 from '../img/Client logos/ati.png';
import bp5 from '../img/Client logos/blessing.png';
import bp6 from '../img/Client logos/denimach.png';
import bp7 from '../img/Client logos/logo-full.png';
import bp8 from '../img/Client logos/moham.png';
import bp9 from '../img/Client logos/nice.jpg';
import bp10 from '../img/Client logos/pakizagroup.png';
import bp11 from '../img/Client logos/rahim_group_logo-01-e1646591491708.png';
import c1 from '../img/our clients/textile.jpg';
import c2 from '../img/our clients/heavy.jpg';
import c3 from '../img/our clients/fmcg.jpg';
import c4 from '../img/our clients/food.jpg';
import c5 from '../img/our clients/powerplant.jpg';
import c6 from '../img/our clients/glass.jpg';
import c7 from '../img/our clients/ceramic.jpg';
import c8 from '../img/our clients/cement.jpg';
import c9 from '../img/our clients/Plastic.jpeg';
import c10 from '../img/our clients/pharma.jpg';
import Iframe from 'react-iframe';
import img3 from '../img/05_tehran_-_500.jpg'


const About = () => {
    return (
        <div>
            
            <div className='about'>
            <div className='bg'>
                <Menu></Menu>
                <h1 className='text-white display-5 fw-bold doris pt-lg-5 mt-lg-5 pb-5 mx-auto top'>About Us</h1>
            </div>
        </div>
            <h2 className='heading pt-5 px-3'>A Legal Brief of INNOCUOUS ENERGY International limited (IEIL)</h2>
            <hr className='w-75 mx-auto'></hr>
            <h5 className='px-lg-5 mx-lg-3 padding-left'>
            A leading Company who engages in the provision of energy & power businesses. It operates through the following segments: Liquid Pipelines, Gas Distribution & Storage, Gas Transmission & Midstream, Renewable Power Generation, and Energy Services. The Liquids Pipelines segment consists of common carrier and contract crude oil, natural gas, gas liquids and refined products pipelines and terminals.  We evaluate energy programs and plans so that future performance can be enhanced. We support our clients’ sustainable energy goals through end-to-end strategic, analytical and technical assistance. We assess energy efficiency and renewable energy opportunities and analyze their costs, benefits, and risks. We design leading programs, plans and policies that can realistically meet end-user’s requirements for future energy security and sustainability. The Company is operating its business operations in the international arena specially in USA, Canada, Europe, Turkey, Middle-Est and Asia Pacific regions.<br></br><br></br>  
            The company is founded in 2015 and is head quartered in Dhaka, the capital city of Bangladesh.<br></br><br></br>

            <strong>Services</strong><br></br><br></br>
            INNOCUOUS ENERGY is one of the most trust-worthy leading EPC (Engineering, Procurement & Construction) Company comprised of highly experienced professionals in energy and power sectors.<br></br><br></br> 
            <strong>Energy Sector</strong><br></br><br></br>
            INNOCUOUS ENERGY is working in energy sector through oil and natural gas EPC (Engineering, Procurement and Construction) projects and solutions. Our company shares its knowledge and experience with its partners in projects planning, design, engineering and consulting services and implementation in local and foreign markets. The key projects areas: <br></br><br></br>
                <ul className=''>
                    <li>
                    High Pressure Gas Transmission Line
                    </li>
                    <li>
                    Low Pressure Gas Distribution Line 
                    </li>
                    <li>
                    City Gate Station 
                    </li>
                    <li>
                    Town Bordering Station
                    </li>
                    <li>
                    Skid Mounted Regulating & Metering Station (RMS).  
                    </li>
                    <li>
                    Remote Metering and Energy Management Projects.
                    </li>
                    <li>
                    Customized Projects.
                    </li>
                    <li>
                    Supply Chain of the Equipment and Spare Parts.
                    </li>
                </ul><br></br>
                <strong>Power Sector</strong><br></br><br></br>
                We have a huge experience in Industrial Gas & Gas Pipeline Project. Several high-profile companies have been served & serving by us since 2015. Several RMS projects are ongoing. By our exclusive service we achieve clients trust in this sector. We have all gas related solution and listed high-profile gas contractor to complete our gas challenges within the shortest possible time. Our company shares its knowledge and experience with its partners by submitting projects, engineering and consult anting services in local and foreign markets.<br></br><br></br>

                <ul>
                    <li>
                    High Pressure Stations (City Gates, Power Plant Gas Supply Stations)
                    Medium Pressure Stations 
                    </li>
                    <li>
                    (Domestic Stations from steel pipeline for Gas Distributions Companies and Industry)
                    Low Pressure Stations (Commercial Service Boxes, Small Capacity Usage Stations) in the field of Natural Gas and Oil
                    </li>
                </ul>
                We Innocuous Energy International Ltd. is the only sole sales partner in Bangladesh.
            </h5>
            {/* <h2 className='heading pt-5'>History & Growth</h2>
            <hr className='w-25 mx-auto'></hr>
            <h5 className='px-5 mx-lg-3'>
                After establishing this company on 9th November 2015 till now it is run fluently. And expand its business day by day.<br></br>
                It was started with a small number of employee (9 members). Now it has a balanced, presentable and expert engineering and technical team of 30 members. Who will able to handle any kind of project or development.<br></br>
                Now a days <strong>Innocuous Energy</strong> is the business partner and distributor of world class 5 renown USA & EUROPE based companies and several China company.<br></br>
                &nbsp;&nbsp;&nbsp;-	Our turn over is increased to almost 10 crores.<br></br>
                &nbsp;&nbsp;&nbsp;-	Innocuous Energy has its own 7 stored Building with corporate office.<br></br>
                &nbsp;&nbsp;&nbsp;-	Successfully enriched its clients list day by day providing a standard of service.<br></br>
                &nbsp;&nbsp;&nbsp;-	Now we have at least 15 projects ongoing and 5 mega projects with different Group of Industries. <br></br>
                &nbsp;&nbsp;&nbsp;-	Within few more years we will able to make Innocuous Energy as a brand in Environment & Energy sector. 
            </h5> */}
            <h2 className='heading pt-5'>Mission & Vision</h2>
            <hr className='w-25 mx-auto'></hr>
            <h5 className='px-lg-5 mx-lg-3 padding-left'>
                Become a Leading Company in Environment & Energy. Establish Innocuous Energy as a Brand. Become a manufacturing company in the upcoming year. Build a potential and productive individual engineering team for each sector. Innocuous Energy will self-revelation in Software Industry very soon. Bring digitalization to people’s door step and accelerate government 2021 vision activities.
            </h5>
            <div className='about mt-5'>
                <div className='bg2'>
                    <div className='row px-lg-5 mx-lg-3 padding-left'>
                        <h2 className='heading pt-4'>IEIL Provided Services</h2>
                        <hr className='w-25 mx-auto'></hr>
                        <h5 className=''>
                            We afford top-level research, analysis, planning and strategic counsel in support of clients’ Energy Efficiency (EE) and Renewable Energy (RE) goals for new industries as well as to enhance efficiency for prevailing industries.<br></br><br></br>
                        </h5>
                        <div className='col-lg-6 col-sm-12'>
                            <h5 className='px-lg-5 mx-lg-3 padding-left'>
                                &#10004; Gas Pipe Line Works <br></br>
                                &#10004; Regulating & Metering Station Works <br></br>
                                &#10004; Oxygen Generator Plant <br></br>
                                &#10004; CHP (Combined Heating & Power)  <br></br>
                                &#10004; Natural Gas Processing for Lines, Stations. <br></br>
                                &#10004; Generator, Solar Power. <br></br>
                                &#10004; Power System Installation, Testing and Commissioning. <br></br>
                            </h5>
                        </div>
                        <div className='col-lg-6 col-sm-12'>
                            <h5 className='px-lg-5 mx-lg-3 padding-left'>
                                &#10004; Power System for 11kv, 33kv, 132kv Switching and Substation. <br></br>
                                &#10004; Smart Energy Metering System.  <br></br>
                                &#10004; Flow Meter <br></br>
                                &#10004; LDR (Low differential Regulator)  <br></br>
                                &#10004; Exhaust Gas Boiler.   <br></br>
                                &#10004; Zero Liquid Discharge.  <br></br>
                                &#10004; LPS (Lightening Protection System)   <br></br>
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
            <h2 className='heading pt-5'>Our Valuable Clients </h2>
            <hr className='w-25 mx-auto'></hr>
            <CardGroup className='px-lg-5 padding-left'>
                    <Card className="mx-3 px-4 pt-4 mb-0 pb-0 bg-transparent border-0">
                        <Card.Img variant="top" src={bp1} className='service'/>
                        <Card.Body className="px-0">
                        <Card.Title className="pb-0 mb-0 doris">Pandora Sweater Ltd.</Card.Title>
                        </Card.Body>
                    </Card>
                    <Card className="mx-3 px-4 pt-4 mb-0 pb-0 bg-transparent border-0">
                        <Card.Img variant="top" src={bp2} className='service'/>
                        <Card.Body className="px-0">
                        <Card.Title className="pb-0 mb-0 doris">Anwar Cement Ltd.</Card.Title>
                        </Card.Body>
                    </Card>
                    <Card className="mx-3 px-4 pt-4 mb-0 pb-0 bg-transparent border-0">
                        <Card.Img variant="top" src={bp4} className='service'/>
                        <Card.Body className="px-0">
                        <Card.Title className="pb-0 mb-0 doris">ATI Ceramic Industries Ltd</Card.Title>
                        </Card.Body>
                    </Card>
                    <Card className="mx-3 px-4 pt-4 mb-0 pb-0 bg-transparent border-0">
                        <Card.Img variant="top" src={bp5} className='service'/>
                        <Card.Body className="px-0">
                        <Card.Title className="pb-0 mb-0 doris">Blessing Knitwear Ltd.
</Card.Title>
                        </Card.Body>
                    </Card>
                    <Card className="mx-3 px-4 pt-4 mb-0 pb-0 bg-transparent border-0">
                        <Card.Img variant="top" src={bp8} className='service'/>
                        <Card.Body className="px-0">
                        <Card.Title className="pb-0 mb-0 doris">Mohammadi Steel Works Ltd.
</Card.Title>
                        </Card.Body>
                    </Card>
                    <Card className="mx-3 px-4 pt-4 mb-0 pb-0 bg-transparent border-0">
                        <Card.Img variant="top" src={bp9} className='service'/>
                        <Card.Body className="px-0">
                        <Card.Title className="pb-0 mb-0 doris">Nice Span Mills Ltd.
</Card.Title>
                        </Card.Body>
                    </Card>
                </CardGroup>
                <div className='row ps-5 pe-lg-3 small'>
                    <div className='col-lg-6 col-sm-12'>
                        <CardGroup className=''>
                            <Card className="mx-3 px-4 pt-4 mb-0 pb-0 bg-transparent border-0">
                                <Card.Img variant="top" src={bp3} className='service'/>
                                <Card.Body className="px-0">
                                <Card.Title className="pb-0 mb-0 doris">Anwar Ispat Ltd.</Card.Title>
                                </Card.Body>
                            </Card>
                            <Card className="mx-3 px-4 pt-4 mb-0 pb-0 bg-transparent border-0">
                                <Card.Img variant="top" src={bp6} className='service'/>
                                <Card.Body className="px-0">
                                <Card.Title className="pb-0 mb-0 doris">Denimach Ltd</Card.Title>
                                </Card.Body>
                            </Card>
                            <Card className="mx-3 px-4 pt-4 mb-0 pb-0 bg-transparent border-0">
                                <Card.Img variant="top" src={bp10} className='service'/>
                                <Card.Body className="px-0">
                                <Card.Title className="pb-0 mb-0 doris">Pakiza Cotton Spinning Mills Ltd.</Card.Title>
                                </Card.Body>
                            </Card>
                        </CardGroup>
                    </div>
                    <div className='col-lg-6 col-sm-12'>
                        <CardGroup className='client-img'>
                            <Card className="me-5 px-4 pt-4 mb-0 pb-0 bg-transparent border-0">
                                <Card.Img variant="top" src={bp7} className='service'/>
                                <Card.Body className="px-0">
                                <Card.Title className="pb-0 mb-0 doris">Ahsan Composite Ltd.</Card.Title>
                                </Card.Body>
                            </Card>
                            <Card className="me-5 px-4 pt-4 mb-0 pb-0 bg-transparent border-0">
                                <Card.Img variant="top" src={bp11} className='service'/>
                                <Card.Body className="px-0">
                                <Card.Title className="pb-0 mb-0 doris">Rahim Steel Ltd</Card.Title>
                                </Card.Body>
                            </Card>
                        </CardGroup>
                    </div>
                </div>
                <h2 className='heading pt-5'>Our Working Sectors</h2>
                <hr className='w-25 mx-auto'></hr>
                <CardGroup className='px-5 pt-3'>
                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                        <Card.Img variant="top" className="rounded-3" src={c1} height="100%"/>
                        <Card.ImgOverlay className="text-light overlay">
                            <Card.Title className=''>
                                <h1 className="h3">Textile Industry</h1>
                            </Card.Title>
                        </Card.ImgOverlay>
                    </Card>
                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                        <Card.Img variant="top" className="rounded-3" src={c2} height="100%" />
                        <Card.ImgOverlay className="text-light overlay">
                            <Card.Title>
                                <h1 className="h3 my-auto">Heavy Industry</h1>
                            </Card.Title>
                        </Card.ImgOverlay>
                    </Card>
                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                        <Card.Img variant="top" className="rounded-3" src={c3} height="100%"/>
                        <Card.ImgOverlay className="text-light overlay">
                            <Card.Title>
                                <h1 className="h3 my-auto">FMCG</h1>
                            </Card.Title>
                        </Card.ImgOverlay>
                    </Card>
                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                        <Card.Img variant="top" className="rounded-3" src={c4} height="100%"/>
                        <Card.ImgOverlay className="text-light overlay">
                            <Card.Title>
                                <h1 className="h3 my-auto">Food & Bevarage Comapny</h1>
                            </Card.Title>
                        </Card.ImgOverlay>
                    </Card>
                </CardGroup>
                <CardGroup className='px-5'>
                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                        <Card.Img variant="top" className="rounded-3" src={c5} height="100%"/>
                        <Card.ImgOverlay className="text-light overlay">
                            <Card.Title>
                                <h1 className="h3 my-auto">Power Plant</h1>
                            </Card.Title>
                        </Card.ImgOverlay>
                    </Card>
                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                        <Card.Img variant="top" className="rounded-3" src={c6} height="100%"/>
                        <Card.ImgOverlay className="text-light overlay">
                            <Card.Title>
                                <h1 className="h3 my-auto">Glass Industries</h1>
                            </Card.Title>
                        </Card.ImgOverlay>
                    </Card>
                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                        <Card.Img variant="top" className="rounded-3" src={c7} height="100%"/>
                        <Card.ImgOverlay className="text-light overlay">
                            <Card.Title>
                                <h1 className="h3 my-auto">Ceramic Industries</h1>
                            </Card.Title>
                        </Card.ImgOverlay>
                    </Card>
                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                        <Card.Img variant="top" className="rounded-3" src={c8} height="100%"/>
                        <Card.ImgOverlay className="text-light overlay">
                            <Card.Title>
                                <h1 className="h3 my-auto">Cement Factory</h1>
                            </Card.Title>
                        </Card.ImgOverlay>
                    </Card>
                </CardGroup>
                <div className='row ps-5 pe-lg-3 small'>
                    <div className='col-lg-6 col-sm-12'>
                        <CardGroup className=''>
                            <Card className="mx-3 mb-5 bg-transparent border-0 service">
                                <Card.Img variant="top" className="rounded-3" src={c9} height="100%"/>
                                <Card.ImgOverlay className="text-light overlay">
                                    <Card.Title>
                                        <h1 className="h3 my-auto">Plastic Industries</h1>
                                    </Card.Title>
                                </Card.ImgOverlay>
                            </Card>
                            <Card className="mx-3 mb-5 bg-transparent border-0 service">
                                <Card.Img variant="top" className="rounded-3" src={c10} height="100%"/>
                                <Card.ImgOverlay className="text-light overlay">
                                    <Card.Title>
                                        <h1 className="h3 my-auto">Pharmaceuticals</h1>
                                    </Card.Title>
                                </Card.ImgOverlay>
                            </Card>
                    </CardGroup>
                    </div>
                </div>
                <h2 className='heading pt-5'>Our Intentions</h2>
                <hr className='w-25 mx-auto'></hr>
                <h5  className='mx-5'>
                    <ul>
                        <li>
                            Support Government to fulfill vison 2021.
                        </li>
                        <li>
                            We also intend to digitalize utility process by establishing new technology.
                        </li>
                        <li>
                            Consume power, saving natural resources 
                        </li>
                        <li>
                            Use minimum electrical equipment consume maximum benefits. 
                        </li>
                        <li>
                            Avoid system mismanagement or crush by remote monitoring 
                        </li>
                    </ul>
                </h5>
                <h2 className='heading pt-5'>Address Details</h2>
                <hr className='w-25 mx-auto'></hr>
                <div className='row mx-5 mb-5'>
                    <div className='col-lg-6 col-sm-12'>
                        <h5>
                        <strong>Corporate Head Office:</strong><br></br>
                        Innocuous Energy International Ltd.<br></br>
                        House No# 12, Road No# 06,<br></br>
                        Nikunja-2, Khilkhet,
                        Dhaka-1229<br></br>
                        Cell: +88 01712 604631<br></br>
                        Phone: +88 02 41040407 Fax: + 88 02 41040408<br></br>
                        Web: www. Innocuousenergy.com
                        </h5>
                        <Iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d58388.60298554103!2d90.411181!3d23.843916!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x11e245d769b7e4b9!2sInnocuous%20Energy%20International%20Limited!5e0!3m2!1sen!2sus!4v1651662645718!5m2!1sen!2sus" width="600" height="450" style={{border:"0"}} allowfullscreen="" loading="lazy" className='map mb-5'></Iframe>
                    </div>
                    <div className='col-lg-6 col-sm-12'>
                        <div>
                            <h5 className=''>
                            <strong>Registered Office:</strong><br></br>
                            Innocuous Energy International Ltd.<br></br>
                            House#420/17 (Ka), Abdus Sattar Saroni, <br></br>
                            Vurulia, Gazipur City, Gazipur-1700<br></br>
                            Phone: +88 01712 604631<br></br>
                            E-mail: info@innocuousenergy.com<br></br>
                            Web: www. Innocuousenergy.com
                            </h5>
                            <Iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3644.3817985353257!2d90.41202571446094!3d24.017597984434353!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755db002b7e5711%3A0xd6fdbf46c824b696!2sInnocuous%20Energy%20International%20Ltd!5e0!3m2!1sen!2sbd!4v1641908333616!5m2!1sen!2sbd" width="600" height="450" style={{border:"0"}} allowfullscreen="" loading="lazy" className='map'></Iframe>
                        </div>
                    </div>
                </div>
                
        </div>
    );
};

export default About;