import React from 'react';
import './ERW.css';
import Menu from '../../../../Menu/Menu';
import { Card, CardGroup, Table } from 'react-bootstrap';
import t21 from '../../../../../img/Line Pipe/ewr/t21.PNG';
import t22 from '../../../../../img/Line Pipe/ewr/t22.PNG';
import t31 from '../../../../../img/Line Pipe/ewr/t31.PNG';
import t32 from '../../../../../img/Line Pipe/ewr/t32.PNG';
import t33 from '../../../../../img/Line Pipe/ewr/t33.PNG';
import t4 from '../../../../../img/Line Pipe/ewr/t4.PNG';
import p1 from '../../../../../img/Line Pipe/ewr/p1.png';
import p2 from '../../../../../img/Line Pipe/ewr/p2.jpg';
import erw1 from '../../../../../img/Line Pipe/ewr/erw1.jpg';
import erw2 from '../../../../../img/Line Pipe/ewr/erw2.jpg';
import erw3 from '../../../../../img/Line Pipe/ewr/erw3.jpg';
import erw4 from '../../../../../img/Line Pipe/ewr/erw4.jpg';
import erw5 from '../../../../../img/Line Pipe/ewr/erw5.jpg';
import erw6 from '../../../../../img/Line Pipe/ewr/erw6.jpg';




const ERW = () => {
    return (
        <div className='mb-5'>
            
            <div className='about2'>
            <div className='bg'>
                <Menu></Menu>
                <h1 className='text-white display-4 fw-bold doris pt-5 mt-5 pb-5 mx-auto'>ERW/HFW Pipe (Electric resistance welded)</h1>
            </div>
        </div>
            <h2 className='heading pt-5'>ERW/HFW Pipe (Electric resistance welded)</h2>
            <hr className='w-75 mx-auto'></hr>
            <h5 className='px-5 mx-lg-3'>
                We Are Experienced Suppliers for Metal. Feel Free to Do Business with Us Right Away. Our Supplied metals are Highly Durable. ERW pipe With Complete specifications, High Quality Materials. Top Quality & Best Price. Custom Made Stainless.
                <Table striped bordered hover responsive className='mt-4'>
                    <tbody>
                        <tr>
                            <td>Product:</td>
                            <td>ERW (Electric Resistance Welded) Steel Pipe, HFW Pipe, ERW Black Pipe</td>
                        </tr>
                        <tr>
                            <td>Application:</td>
                            <td>Used for Oil/Gas/Water transmission, Machinery Manufacturing</td>
                        </tr>
                        <tr>
                            <td>Size:</td>
                            <td>OD: 21.3-660mm<br></br>
                                WT: 1.0-20mm<br></br>
                                LENGTH: 5.8/6/11.8/12m</td>
                        </tr>
                        <tr>
                            <td>Pipe Standard:</td>
                            <td>API 5L &nbsp; PSL1/PSL2  &nbsp;  Gr.A,Gr.B,X42,X46,X52,X56,X60,X65,X70<br></br>
                                ASTM A53/A252/A500/A178/A135<br></br>
                                EN10210/EN10219/EN10217/EN10208/EN10297<br></br>
                                JIS G3441/G3444/G3445/G3452/G3454/G3456<br></br>
                                BS1387/AS1163</td>
                        </tr>
                    </tbody>
                </Table>
            </h5>
            <div className='row px-5 pe-3 small'>
                <CardGroup className='pe-5'>
                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                        <Card.Img variant="top" className="rounded-3" src={erw6} height="100%"/>
                        <Card.ImgOverlay className="text-light overlay">
                            
                        </Card.ImgOverlay>
                    </Card>
                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                        <Card.Img variant="top" className="rounded-3" src={erw1} height="100%"/>
                        <Card.ImgOverlay className="text-light overlay">
                            
                        </Card.ImgOverlay>
                    </Card>
                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                        <Card.Img variant="top" className="rounded-3" src={erw3} height="100%"/>
                        <Card.ImgOverlay className="text-light overlay">

                        </Card.ImgOverlay>
                    </Card>
                   
                </CardGroup>
            </div>
            <div className='row ps-5 pe-3 small'>
                    {/* <div className='col-lg-6 col-sm-12'> */}
                        <CardGroup className='pe-5'>
                            <Card className="mx-3 mb-5 bg-transparent border-0 service">
                                <Card.Img variant="top" className="rounded-3" src={erw2} height="100%"/>
                                <Card.ImgOverlay className="text-light overlay">

                                </Card.ImgOverlay>
                            </Card>
                            <Card className="mx-3 mb-5 bg-transparent border-0 service">
                                <Card.Img variant="top" className="rounded-3" src={erw4} height="100%"/>
                                <Card.ImgOverlay className="text-light overlay">
                                    
                                </Card.ImgOverlay>
                            </Card>
                            <Card className="mx-3 mb-5 bg-transparent border-0 service">
                                <Card.Img variant="top" className="rounded-3" src={erw5} height="100%"/>
                                <Card.ImgOverlay className="text-light overlay">
                                   
                                </Card.ImgOverlay>
                            </Card>
                    </CardGroup>
                    {/* </div> */}
                </div>
            <div className='about2 mt-5'>
                <div className='bg2'>
                    <h3 className='heading pt-3 ps-5 ms-lg-3 d-flex justify-content-start'>Description</h3>
                    <hr className='w-25 ms-5'></hr>
                    <h5 className='px-5 mx-lg-3 pb-3'>
                        The High-Frequency Longitudinal Seam Welded Steel pipe is also called ERW steel pipe, it's manufactured by cold-forming a sheet of steel into a cylindrical shape. Current is then passed between the two edges of the steel to heat the steel to a point at which the edges are forced together to form a bond without the use of welding filler material.<br></br><br></br>
                        <strong>MAIN CHARACTERISTICS OF ERW STEEL PIPES:</strong><br></br>
                        High-security welding seam: as a result of special welding method of melting of the parent metal together without filler metal, the welding property is better and the welding seam is much smaller than spiral welded pipes.<br></br>
                        Cost effective and short production period<br></br>
                        A wide range of thickness/diameter ratio, covering hundreds of specification
                        In –line inspection and testing.<br></br><br></br>
                        <strong>APPLICATION OF ERW PIPES:</strong><br></br>
                        Casing and tubing: lined inside drilling well. Seamless pipes use to be the only choice for lining pipes inside drilling well, but as the worldwide acknowledgement of ERW pipe’s high-quality weld seam and the cost-effective, more and more projects have been choosing ERW instead of seamless pipe.<br></br>
                        Constructional structural pipes (round, square and rectangular): round, square and rectangular ERW structural pipes are widely used in frameworks of shopping centers, theatres, airports, bridges, workshops etc.<br></br>
                        Low-pressure flow transmitting: ERW pips are also used in water supply; cooling water supply for plants and equipment; low pressure thermal distributing; coal slurry transportation and more.<br></br>
                        Pipes for raw materials in the manufacturing industry: automobile vent-pipe; low and intermediate pressure boiler pipes; bulk matters transportation pipes.<br></br>
                        Other application: piling pipes, furniture fabricating and more.
                    </h5>
            </div>
            </div>
            <h3 className='heading pt-5 ps-5 ms-lg-3 d-flex justify-content-start'>Specification</h3>
            <hr className='w-25 ms-5'></hr>
            <h4 className='ps-5 ms-lg-3 doris fw-bold text-decoration-underline'>Table - 1:</h4>
            <img src={t21} alt="" className='image2'/>
            <img src={t22} alt="" className='image2'/>
            
            <h4 className='ps-5 ms-lg-3 doris mt-5 fw-bold text-decoration-underline'>Table - 2:</h4>
            <img src={t31} alt="" className='image2'/>
            <img src={t32} alt="" className='image2'/>
            <img src={t33} alt="" className='image2'/>

            <h3 className='heading pt-5 ps-5 ms-lg-3 d-flex justify-content-start'>Standards</h3>
            <hr className='w-25 ms-5'></hr>
            <img src={t4} alt="" className='image2'/>

            <h3 className='heading pt-5 ps-5 ms-lg-3 d-flex justify-content-start'>Process of Manufacture</h3>
            <hr className='w-25 ms-5'></hr>
            <img src={p1} alt="" className='image2'/>
            <img src={p2} alt="" width="90%"/>
                
        </div>
    );
};

export default ERW;