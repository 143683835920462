import React from 'react';
import './CPTR.css';
import Menu from '../../Menu/Menu';
import { Card, CardGroup, Table } from 'react-bootstrap';
import cptr1 from '../../../../../ieil-client-side/src/img/cptr/1.jpg';
import cptr2 from '../../../../../ieil-client-side/src/img/cptr/2.jpg';
import cptr3 from '../../../../../ieil-client-side/src/img/cptr/3.jpg';
import cptr4 from '../../../../../ieil-client-side/src/img/cptr/4.jpg';
import cptr5 from '../../../../../ieil-client-side/src/img/cptr/5.jpg';
import cptr6 from '../../../../../ieil-client-side/src/img/cptr/6.jpg';
import cptr7 from '../../../../../ieil-client-side/src/img/cptr/7.jpg';


const CPTR = () => {
    return (
        <div className='mb-5'>
            
            <div className='about3'>
            <div className='bg'>
                <Menu></Menu>
                <h1 className='text-white display-5 fw-bold doris pt-5 mt-5 pb-5 mx-auto'>Cathodic Protection Transformer Rectifier (CPTR)</h1>
            </div>
        </div>
            <h2 className='heading pt-5'>Cathodic Protection Transformer Rectifier (CPTR)</h2>
            <hr className='w-75 mx-auto'></hr>
            <h5 className='px-5 mx-lg-3'>
                <div className='row'>
                    <div className='col-lg-6 col-md-6 col-sm-12 pt-3'>
                        AUTOMATIC CATHODIC PROTECTION POWER SYSTEM
                        The ieCPPS 2100 Automatic Cathodic Protection
                        Power System is an Alternating Current (AC) to Direct
                        Current (DC) Power Supply which delivers a dc
                        continuous power at output based on the load
                        demands within its design capacity. It is specially
                        designed for Cathodic Protection System for Oil and
                        Gas Station, Pipelines, Merin and general cathodic
                        protection purposes.
                        There are multiple tapping systems to switch
                        automatically from one tap to another based on the
                        load current. The automatic tap switching provides
                        the optimum uses of power transformers.
                        This device is designed on a close loop system which
                        automatically can adjust the load and set voltage. Data
                        Logging, Remote Control of Input Power, Output Power,
                        Tap Switching are the advanced and enhanced fac
                        facilities that provides smart and remote operation
                        monitoring and control for the end-users.
                        Key Features
                        <ul>
                            <li>
                            Automatic Output Voltage Control
                            </li>
                            <li>
                            Over Load Control
                            </li>
                            <li>
                            Over Current Control
                            </li>
                            <li>
                            Input and Output Surge Protection
                            </li>
                            <li>
                            Input and Output Short Circuit Protection
                            </li>
                            <li>
                            Remote Monitoring and Control
                            </li>
                            <li>
                            Global safety, quality and environment Standard
                            </li>
                            <li>
                            Compliance: IEC 61643-1, IEC 60898-1,
                            </li>
                            <li>
                            Safety: UL 1642, IEC
                            </li>
                            <li>
                            Health Safety: ISO 45001 :2018
                            </li>
                            <li>
                            Quality: ISO 9001:2015, RoHS, REACH
                            </li>
                            <li>
                            Environment: ISO 14001: 2015
                            </li>
                        </ul>
                        
                        Typical Applications
                        <ul>
                            <li>
                            Oil and Gas Station, Pipeline
                            </li>
                            <li>
                            Merin Ships and Bulk Vessels
                            </li>
                            <li>
                            Controlled DC Power System
                            </li>
                            <li>
                            Industrial DC Power Applications
                            </li>
                        </ul>
                    </div>
            <div className='col-lg-6 col-md-6 col-sm-12'>
            <div className='row pt-3'>
                            <CardGroup className='p-0'>
                                <Card className="mx-3 mb-5 bg-transparent border-0 service">
                                    <Card.Img variant="top" className="rounded-3" src={cptr1} height="100%"/>
                                    <Card.ImgOverlay className="text-light overlay">

                                    </Card.ImgOverlay>
                                </Card>
                                <Card className="mx-3 mb-5 bg-transparent border-0 service">
                                    <Card.Img variant="top" className="rounded-3" src={cptr2} height="100%"/>
                                    <Card.ImgOverlay className="text-light overlay">

                                    </Card.ImgOverlay>
                                </Card>
                            </CardGroup>
                            {/* <CardGroup className='p-0'>
                                <Card className="mx-3 mb-5 bg-transparent border-0 service">
                                    <Card.Img variant="top" className="rounded-3" src={cptr3} height="100%"/>
                                    <Card.ImgOverlay className="text-light overlay">

                                    </Card.ImgOverlay>
                                </Card>
                                <Card className="mx-3 mb-5 bg-transparent border-0 service">
                                        <Card.Img variant="top" className="rounded-3" src={cptr4} height="100%"/>
                                        <Card.ImgOverlay className="text-light overlay">

                                        </Card.ImgOverlay>
                                    </Card>
                            </CardGroup> */}
                            {/* <CardGroup className='p-0'>
                                <Card className="mx-3 mb-5 bg-transparent border-0 service">
                                        <Card.Img variant="top" className="rounded-3" src={cptr5} height="100%"/>
                                        <Card.ImgOverlay className="text-light overlay">

                                        </Card.ImgOverlay>
                                    </Card>
                                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                                        <Card.Img variant="top" className="rounded-3" src={cptr6} height="100%"/>
                                        <Card.ImgOverlay className="text-light overlay">

                                        </Card.ImgOverlay>
                                    </Card>
                            </CardGroup> */}
                            {/* <CardGroup className='p-0'>
                                <Card className="mx-3 mb-5 bg-transparent border-0 service" style={{height: "70%"}}>
                                        <Card.Img variant="top" className="rounded-3" src={cptr7} height="100%"/>
                                        <Card.ImgOverlay className="text-light overlay" height="70%">

                                        </Card.ImgOverlay>
                                    </Card>
                            </CardGroup> */}
                        </div>
                    </div>
                    </div>
            </h5>

            
            
        </div>
    );
};

export default CPTR;