import React from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import logo from "../../img/logo.png";
import './Menu.css';
import "react-bootstrap-submenu/dist/index.css";
import { DropdownSubmenu, NavDropdownMenu} from "react-bootstrap-submenu";
import { HashLink as Link } from 'react-router-hash-link';

const Menu = () => {
    return (
        <>
            <Navbar expand="lg" className="navbar p-0">
            <Container>
                <Navbar.Brand>
                    <NavLink to="/home">
                        <img src={logo} alt="" width="80"/>
                    </NavLink>   
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                    <NavLink to="/home" className="items d-flex justify-content-center">
                    <li>Home</li>
                    </NavLink>
                    <NavLink to="/about" className="items d-flex justify-content-center">
                    <li>About</li>
                    </NavLink>

                    <NavDropdownMenu title={<span className='blue'>EPC</span>} id="collapsible-nav-dropdown" className='ps-0 item1 d-flex align-items-center epcZIndex d-flex justify-content-center'>

                        {/* oil and gas menu */}
                        <DropdownSubmenu href="#action/3.7" title="Oil & Gas">
                            <DropdownSubmenu href="#action/3.7" title="Station">
                                <Link to="/station#cgs" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">EPC Project onsite<br></br> City Gate Station (CGS)</NavDropdown.Item>
                                </Link>
                                <NavLink to="/station" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">EPC Project onsite<br></br> Town Bordering Station (TBS)</NavDropdown.Item>
                                </NavLink>
                                <Link to="/station#drs" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">EPC Project onsite<br></br> Distribution and Regulating<br></br> Station (DRS)</NavDropdown.Item>
                                </Link>
                                <Link to="/station#rms" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">EPC Project onsite<br></br> Bulk Customer Regulating<br></br> and Metering Station (RMS)</NavDropdown.Item>
                                </Link>
                                <NavLink to="/station" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">EPC Project on Skid<br></br> Mounted Regulating &<br></br>Metering Station (RMS)</NavDropdown.Item>
                                </NavLink>
                                {/* <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">EPC Project Remote<br></br> Data Acquisition</NavDropdown.Item>
                                </NavLink>
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">EPC Project on Distributed<br></br>Control System (DCS)</NavDropdown.Item>
                                </NavLink> */}
                            </DropdownSubmenu>
                            <DropdownSubmenu href="#action/3.7" title="Pipeline">
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">EPC Project on Pipeline<br></br> for High Pressure<br></br> Transmission Line</NavDropdown.Item>
                                </NavLink>
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">EPC Project on Pipeline<br></br> for Low Pressure<br></br> Distribution Line</NavDropdown.Item>
                                </NavLink>
                            </DropdownSubmenu>
                            <DropdownSubmenu href="#action/3.7" title="Calibration Gas">
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">EPC Project on Calibration<br></br> Gas for RMS</NavDropdown.Item>
                                </NavLink>
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">EPC Project on Calibration<br></br> Gas for Laboratories</NavDropdown.Item>
                                </NavLink>
                            </DropdownSubmenu>
                        </DropdownSubmenu>

                        {/* Industrial & Medical Gas menu */}
                        <DropdownSubmenu href="#action/3.7" title="Industrial & Medical Gas">
                            <NavLink to="/oxniar" className="text-decoration-none">
                                <NavDropdown.Item href="#action/9.1">EPC Project on Oxygen Plant (PSA & Cryogenic<br></br> Liquid & Gases, Purity up to 99.999%)</NavDropdown.Item>
                            </NavLink>
                            <NavLink to="/oxniar" className="text-decoration-none">
                                <NavDropdown.Item href="#action/9.1">EPC Project on Air Separation Unit (Liquid & <br></br>Gases, Purity up to 99.999%)</NavDropdown.Item>
                            </NavLink>
                            <Link to="/oxniar#ox" className="text-decoration-none">
                                <NavDropdown.Item href="#action/9.1">EPC Project on Oxygen Plant (PSA & Cryogenic)</NavDropdown.Item>
                            </Link>
                            <Link to="/oxniar#ni" className="text-decoration-none">
                                <NavDropdown.Item href="#action/9.1">EPC Project on Nitrogen Plant</NavDropdown.Item>
                            </Link>
                            <Link to="/oxniar#ar" className="text-decoration-none">
                                <NavDropdown.Item href="#action/9.1">EPC Project on Argon Plant</NavDropdown.Item>
                            </Link>
                        </DropdownSubmenu>

                        {/* power system menu */}
                        {/* <DropdownSubmenu href="#action/3.7" title="Power System">
                            <NavLink to="/about" className="text-decoration-none">
                                <NavDropdown.Item href="#action/9.1">EPC Project on 0.415KV, 11KV, 33 KV Sub-station</NavDropdown.Item>
                            </NavLink>
                            <NavLink to="/about" className="text-decoration-none">
                                <NavDropdown.Item href="#action/9.1">Metering Solution</NavDropdown.Item>
                            </NavLink>
                            <NavLink to="/about" className="text-decoration-none">
                                <NavDropdown.Item href="#action/9.1">EPC on Remote Data Acquisition</NavDropdown.Item>
                            </NavLink>
                        </DropdownSubmenu> */}

                        {/* Remote Data Acquisition menu */}
                        {/* <DropdownSubmenu href="#action/3.7" title={<span>SCADA, DCS & Remote<br></br> Data Acquisition</span>}>
                            <NavLink to="/about" className="text-decoration-none">
                                <NavDropdown.Item href="#action/9.1">EPC Project on Remote Data Acquisition<br></br> of oil & gas flow meters</NavDropdown.Item>
                            </NavLink>
                            <NavLink to="/about" className="text-decoration-none">
                                <NavDropdown.Item href="#action/9.1">EPC Project on Remote Data Acquisition<br></br> of energy meters</NavDropdown.Item>
                            </NavLink>
                            <NavLink to="/about" className="text-decoration-none">
                                <NavDropdown.Item href="#action/9.1">EPC Project on Remote Data Acquisition<br></br> of total utility (oil, gas, water and energy meters)</NavDropdown.Item>
                            </NavLink>
                        </DropdownSubmenu> */}

                    </NavDropdownMenu>

                    <NavDropdownMenu title={<span className='blue'>Products</span>} id="collapsible-nav-dropdown" className='ps-0 item1 d-flex align-items-center prodZIndex d-flex justify-content-center'>
                        <NavLink to="/inverter" className="text-decoration-none">
                            <NavDropdown.Item href="#action/9.1">i-Inverter</NavDropdown.Item>
                        </NavLink>
                        <NavLink to="/cptr" className="text-decoration-none">
                            <NavDropdown.Item href="#action/9.1">i-CPTR (Power Supply for Pipeline Cathodic Protection)</NavDropdown.Item>
                        </NavLink>
                    </NavDropdownMenu>

                    <Link to="/home#service" className="items d-flex justify-content-center">
                    <li>Services</li>
                    </Link>

                    <NavDropdownMenu title={<span className='blue'>More</span>} id="collapsible-nav-dropdown" alignRight={true} className='ps-0 item1 d-flex align-items-center menuZIndex d-flex justify-content-center'>
                    <div>
                    <DropdownSubmenu href="#action/3.7" title="Procurement & Supply Chain">

                        {/* line pipe menu */}
                        <DropdownSubmenu href="#action/3.7" title="Line pipe">

                            <DropdownSubmenu href="#action/3.7" title="Welded Pipe & Tube">
                                <NavLink to="/erw" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">ERW/HFW Pipe (Electric resistance welded)</NavDropdown.Item>
                                </NavLink>
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">SSAW/SAWH Pipe (Spiral Submerged Arc-Welded)</NavDropdown.Item>
                                </NavLink>
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">LSAW/EFW Pipe (Longitudinal Submerged Arc-Welded)</NavDropdown.Item>
                                </NavLink>
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">RHS – Rectangular Hollow Sections</NavDropdown.Item>
                                </NavLink>
                            </DropdownSubmenu>
                            
                            <DropdownSubmenu href="#action/3.7" title="Seamless Steel Pipe">
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">API 5L Line Pipe</NavDropdown.Item>
                                </NavLink>
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">Carbon Seamless Steel Pipe</NavDropdown.Item>
                                </NavLink>
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">Structural Steel Pipe</NavDropdown.Item>
                                </NavLink>
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">Boiler Tubes</NavDropdown.Item>
                                </NavLink>
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">Low Temperature Pipe</NavDropdown.Item>
                                </NavLink>
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">Alloy Pressure Pipe</NavDropdown.Item>
                                </NavLink>
                            </DropdownSubmenu>

                            <DropdownSubmenu href="#action/3.7" title="Stainless-Steel Pipe ">
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">Seamless Stainless-Steel Pipe </NavDropdown.Item>
                                </NavLink>
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">Welded Stainless Steel Pipe</NavDropdown.Item>
                                </NavLink>
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">Nickel Alloy Pipe & Tube</NavDropdown.Item>
                                </NavLink>
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">Stainless Steel Screen Pipe</NavDropdown.Item>
                                </NavLink>
                            </DropdownSubmenu>

                        </DropdownSubmenu>

                        {/* valve menu */}
                        <DropdownSubmenu href="#action/3.7" title="Valve">

                            <DropdownSubmenu href="#action/3.7" title="On-Off Valve">

                                <DropdownSubmenu href="#action/3.7" title="Ball Valves">
                                    <NavLink to="/about" className="text-decoration-none">
                                        <NavDropdown.Item href="#action/9.1">Full Welded Ball<br></br> Valves - E60M</NavDropdown.Item>
                                    </NavLink>
                                    <NavLink to="/about" className="text-decoration-none">
                                        <NavDropdown.Item href="#action/9.1">Full Welded Ball<br></br> Valve, Triple<br></br> Seat Type - E60L</NavDropdown.Item>
                                    </NavLink>
                                    <NavLink to="/about" className="text-decoration-none">
                                        <NavDropdown.Item href="#action/9.1">Top Entry Ball<br></br> Valves - E60T</NavDropdown.Item>
                                    </NavLink>
                                    <NavLink to="/about" className="text-decoration-none">
                                        <NavDropdown.Item href="#action/9.1">Split Body Ball<br></br> Valves, Forged,<br></br> DBB - E601</NavDropdown.Item>
                                    </NavLink>
                                    <NavLink to="/about" className="text-decoration-none">
                                        <NavDropdown.Item href="#action/9.1">Split Body Ball<br></br> Valves, Forged,<br></br> DBB Triple<br></br> Seat Type - E603</NavDropdown.Item>
                                    </NavLink>
                                    <NavLink to="/about" className="text-decoration-none">
                                        <NavDropdown.Item href="#action/9.1">Floating Ball<br></br> Valve - E607</NavDropdown.Item>
                                    </NavLink>
                                </DropdownSubmenu>

                                <DropdownSubmenu href="#action/3.7" title="Gate Valves">
                                    <NavLink to="/about" className="text-decoration-none">
                                        <NavDropdown.Item href="#action/9.1">Cast Steel Wedge<br></br> Gate Valve - E500</NavDropdown.Item>
                                    </NavLink>
                                    <NavLink to="/about" className="text-decoration-none">
                                        <NavDropdown.Item href="#action/9.1">Pressure Seal<br></br> Bonnet Type Wedge<br></br> Gate Valve - E50F</NavDropdown.Item>
                                    </NavLink>
                                    <NavLink to="/about" className="text-decoration-none">
                                        <NavDropdown.Item href="#action/9.1">Through Conduit<br></br> Slab Gate<br></br> Valve - E50B</NavDropdown.Item>
                                    </NavLink>
                                    <NavLink to="/about" className="text-decoration-none">
                                        <NavDropdown.Item href="#action/9.1">Through Conduit<br></br> Double Expanding<br></br> Gate Valve - E50D</NavDropdown.Item>
                                    </NavLink>
                                    <NavLink to="/about" className="text-decoration-none">
                                        <NavDropdown.Item href="#action/9.1">Through Conduit<br></br> Gate Valve,<br></br> Pressure Seal <br></br>Bonnet Type - E50P</NavDropdown.Item>
                                    </NavLink>
                                    <NavLink to="/about" className="text-decoration-none">
                                        <NavDropdown.Item href="#action/9.1">API 6A Gate<br></br> Valves - E50A</NavDropdown.Item>
                                    </NavLink>
                                    <DropdownSubmenu href="#action/3.7" title={<span>Compact Forged<br></br> Gate Valve</span>}>
                                        <NavLink to="/about" className="text-decoration-none">
                                            <NavDropdown.Item href="#action/9.1">Gate Valve<br></br> Bolted Bonnet,<br></br> Rising Stem - E504</NavDropdown.Item>
                                        </NavLink>
                                        <NavLink to="/about" className="text-decoration-none">
                                            <NavDropdown.Item href="#action/9.1">Gate Valve<br></br> Bolted Bonnet,<br></br> Rising Stem - E505</NavDropdown.Item>
                                        </NavLink>
                                    </DropdownSubmenu>
                                </DropdownSubmenu>
                            </DropdownSubmenu>

                            <DropdownSubmenu href="#action/3.7" title={<span>Check Valves /<br></br> Non-Return Valve</span>}>
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">Swing Check Valves - E400</NavDropdown.Item>
                                </NavLink>
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">Dual Disc Check Valves - E40D</NavDropdown.Item>
                                </NavLink>
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">Nozzle Check Valve, Axial Type - E40N</NavDropdown.Item>
                                </NavLink>
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">Check Valve, Lift, BB, Plug type Disc, Welded Integral Body Seat - E404</NavDropdown.Item>
                                </NavLink>
                            </DropdownSubmenu>

                            <DropdownSubmenu href="#action/3.7" title="Compact Forged Valve">
                                <DropdownSubmenu href="#action/3.7" title="Compact Forged Ball Valve">
                                    <NavLink to="/about" className="text-decoration-none">
                                        <NavDropdown.Item href="#action/9.1">Full Welded Floating Ball Valve,<br></br> Forged - E60K</NavDropdown.Item>
                                    </NavLink>
                                    <NavLink to="/about" className="text-decoration-none">
                                        <NavDropdown.Item href="#action/9.1">Full Welded Ball Valve, Floating with <br></br>Cap/Lever Underground Type - E604</NavDropdown.Item>
                                    </NavLink>
                                    <NavLink to="/about" className="text-decoration-none">
                                        <NavDropdown.Item href="#action/9.1">Top Entry Ball Valve,<br></br> Floating Type - E60T</NavDropdown.Item>
                                    </NavLink>
                                </DropdownSubmenu>
                            </DropdownSubmenu>

                            <DropdownSubmenu href="#action/3.7" title="Flow Control Valve">
                                <DropdownSubmenu href="#action/3.7" title="Globe Valve">
                                    <NavLink to="/about" className="text-decoration-none">
                                        <NavDropdown.Item href="#action/9.1">Globe Valve Plug – Ball Disc - E300</NavDropdown.Item>
                                    </NavLink>
                                    <NavLink to="/about" className="text-decoration-none">
                                        <NavDropdown.Item href="#action/9.1">Globe Type Control Valve - E30C</NavDropdown.Item>
                                    </NavLink>
                                    <NavLink to="/about" className="text-decoration-none">
                                        <NavDropdown.Item href="#action/9.1">Globe Valve, BB, Plug Type Disc,<br></br> Weld Integral Body Seat - E304</NavDropdown.Item>
                                    </NavLink>
                                    <NavLink to="/about" className="text-decoration-none">
                                        <NavDropdown.Item href="#action/9.1">Globe Valve, Bolted Bonnet,<br></br> Plug Type Disc - E305</NavDropdown.Item>
                                    </NavLink>
                                </DropdownSubmenu>
                            </DropdownSubmenu>

                        </DropdownSubmenu>

                        {/* regulator menu */}
                        <DropdownSubmenu href="#action/3.7" title="Regulator">

                            <DropdownSubmenu href="#action/3.7" title="Pilot Operated Regulator">
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">Argos</NavDropdown.Item>
                                </NavLink>
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">Argos WA</NavDropdown.Item>
                                </NavLink>
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">Ares N</NavDropdown.Item>
                                </NavLink>
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">Athos</NavDropdown.Item>
                                </NavLink>
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">Brise N</NavDropdown.Item>
                                </NavLink>
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">Brise Plus</NavDropdown.Item>
                                </NavLink>
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">Domus</NavDropdown.Item>
                                </NavLink>
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">Domus HF</NavDropdown.Item>
                                </NavLink>
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">Domus HP</NavDropdown.Item>
                                </NavLink>
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">GA-302-8</NavDropdown.Item>
                                </NavLink>
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">Horus</NavDropdown.Item>
                                </NavLink>
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">Junior 070</NavDropdown.Item>
                                </NavLink>
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">JR CH</NavDropdown.Item>
                                </NavLink>
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">PI</NavDropdown.Item>
                                </NavLink>
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">Proteu</NavDropdown.Item>
                                </NavLink>
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">Urano</NavDropdown.Item>
                                </NavLink>
                            </DropdownSubmenu>

                            <DropdownSubmenu href="#action/3.7" title="Pressure Safety Valve">
                                <DropdownSubmenu href="#action/3.7" title="Slum Shut Valve">
                                    <NavLink to="/about" className="text-decoration-none">
                                        <NavDropdown.Item href="#action/9.1">TWIN</NavDropdown.Item>
                                    </NavLink>
                                    <NavLink to="/about" className="text-decoration-none">
                                        <NavDropdown.Item href="#action/9.1">GIPS</NavDropdown.Item>
                                    </NavLink>
                                    <NavLink to="/about" className="text-decoration-none">
                                        <NavDropdown.Item href="#action/9.1">G10F</NavDropdown.Item>
                                    </NavLink>
                                    <NavLink to="/about" className="text-decoration-none">
                                        <NavDropdown.Item href="#action/9.1">Dilock</NavDropdown.Item>
                                    </NavLink>
                                    <NavLink to="/about" className="text-decoration-none">
                                        <NavDropdown.Item href="#action/9.1">HBC 975</NavDropdown.Item>
                                    </NavLink>
                                    <NavLink to="/about" className="text-decoration-none">
                                        <NavDropdown.Item href="#action/9.1">SBC 187</NavDropdown.Item>
                                    </NavLink>
                                    <NavLink to="/about" className="text-decoration-none">
                                        <NavDropdown.Item href="#action/9.1">SCN</NavDropdown.Item>
                                    </NavLink>
                                    <NavLink to="/about" className="text-decoration-none">
                                        <NavDropdown.Item href="#action/9.1">SSX 176</NavDropdown.Item>
                                    </NavLink>
                                </DropdownSubmenu>
                            </DropdownSubmenu>

                        </DropdownSubmenu>

                        {/* flow meters menu */}
                        <DropdownSubmenu href="#action/3.7" title="Flow Meters">

                            <DropdownSubmenu href="#action/3.7" title="Turbine Meter">
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">TME 400-VCF / TME 400-VMF</NavDropdown.Item>
                                </NavLink>
                                <NavLink to="/about" className="text-decoration-none">
                                    <NavDropdown.Item href="#action/9.1">Turbine Gas Meter TRZ 03</NavDropdown.Item>
                                </NavLink>
                            </DropdownSubmenu>
                            <NavDropdown.Item href="#action/9.1">Ultrasonic Flow Meter</NavDropdown.Item>
                            <NavDropdown.Item href="#action/9.1">Diaphragm meter</NavDropdown.Item>
                            <NavDropdown.Item href="#action/9.1">Orifice Flow Meter</NavDropdown.Item>

                        </DropdownSubmenu>

                        <DropdownSubmenu href="#action/3.7" title="Filter & Separator">
                            <NavDropdown.Item href="#action/9.1">Dry Gas Filters</NavDropdown.Item>
                            <NavDropdown.Item href="#action/9.1">Filter Separator</NavDropdown.Item>
                            <NavDropdown.Item href="#action/9.1">Cyclone Separator</NavDropdown.Item>
                            <NavDropdown.Item href="#action/9.1">Knockout Drum</NavDropdown.Item>
                        </DropdownSubmenu>

                        <DropdownSubmenu href="#action/3.7" title="Heaters">
                            <NavDropdown.Item href="#action/9.1">Indirect Water Bath</NavDropdown.Item>
                            <NavDropdown.Item href="#action/9.1">Electric</NavDropdown.Item>
                            <NavDropdown.Item href="#action/9.1">Heat Exchangers - GHE-BEU</NavDropdown.Item>
                        </DropdownSubmenu>

                        <DropdownSubmenu href="#action/3.7" title="Tanks & Pressure Vessel">
                            <NavDropdown.Item href="#action/9.1">Oil Storage Tank</NavDropdown.Item>
                            <NavDropdown.Item href="#action/9.1">Gas Storage Tank</NavDropdown.Item>
                            <NavDropdown.Item href="#action/9.1">LPG Storage Tank</NavDropdown.Item>
                        </DropdownSubmenu>
                    </DropdownSubmenu>
                    </div>
                        {/* Design Codes & Standers menu */}
                        <DropdownSubmenu href="#action/3.7" title="Design Codes & Standers">
                            <NavLink to="/about" className="text-decoration-none">
                                <NavDropdown.Item href="#action/9.1">Codes & Standard for Station</NavDropdown.Item>
                            </NavLink>
                            <NavLink to="/about" className="text-decoration-none">
                                <NavDropdown.Item href="#action/9.1">Codes & Standard for Pipeline Construction</NavDropdown.Item>
                            </NavLink>
                            <NavLink to="/about" className="text-decoration-none">
                                <NavDropdown.Item href="#action/9.1">Codes & Standard for Pressure Vessel</NavDropdown.Item>
                            </NavLink>
                            <NavLink to="/about" className="text-decoration-none">
                                <NavDropdown.Item href="#action/9.1">Codes & Standard for Equipment for oil and gas </NavDropdown.Item>
                            </NavLink>
                            <NavLink to="/about" className="text-decoration-none">
                                <NavDropdown.Item href="#action/9.1">ISO Standards</NavDropdown.Item>
                            </NavLink>
                        </DropdownSubmenu>
                        <NavLink to="/about" className="text-decoration-none">
                            <NavDropdown.Item href="#action/9.1">Client List</NavDropdown.Item>
                        </NavLink>
                        <NavLink to="/about" className="text-decoration-none">
                            <NavDropdown.Item href="#action/9.1">International Associate Brand  </NavDropdown.Item>
                        </NavLink>
                        <NavLink to="/about" className="text-decoration-none">
                            <NavDropdown.Item href="#action/9.1">On Going Projects and Customer Feedback</NavDropdown.Item>
                        </NavLink>
                        {/* Certification menu */}
                        <DropdownSubmenu href="#action/3.7" title="Certifications">
                            <Link to="/home#certificate" className="text-decoration-none">
                                <NavDropdown.Item href="#action/9.1">ISO 14001:2015 Certification</NavDropdown.Item>
                            </Link>
                            <Link to="/home#certificate" className="text-decoration-none">
                                <NavDropdown.Item href="#action/9.1">ISO 45001:2018 Certification</NavDropdown.Item>
                            </Link>
                            <Link to="/home#certificate" className="text-decoration-none">
                                <NavDropdown.Item href="#action/9.1">ISO 9001:2015 Certification</NavDropdown.Item>
                            </Link>
                        </DropdownSubmenu>
                        <NavLink to="/about" className="text-decoration-none">
                            <NavDropdown.Item href="#action/9.1">News</NavDropdown.Item>
                        </NavLink>
                        <NavLink to="/about" className="text-decoration-none">
                            <NavDropdown.Item href="#action/9.1">Carrier</NavDropdown.Item>
                        </NavLink>

                        </NavDropdownMenu>
                            
                    <Link to="/home#contact" className="items d-flex justify-content-center">
                    <li>Contact</li>
                    </Link>
                </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        </>
        
    );
};

export default Menu;