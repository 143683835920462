import React, { useRef } from 'react';
import { Card, CardGroup, Carousel } from 'react-bootstrap';
import Header from '../Header/Header';
import './Home.css';
import s1 from '../../img/s1.jpg';
import s2 from '../../img/s2.jpg';
import s3 from '../../img/s3.jpg';
import { NavLink } from 'react-router-dom';
import c1 from '../../img/our clients/textile.jpg';
import c2 from '../../img/our clients/heavy.jpg';
import c3 from '../../img/our clients/fmcg.jpg';
import c4 from '../../img/our clients/food.jpg';
import c5 from '../../img/our clients/powerplant.jpg';
import c6 from '../../img/our clients/glass.jpg';
import c7 from '../../img/our clients/ceramic.jpg';
import c8 from '../../img/our clients/cement.jpg';
import c9 from '../../img/our clients/Plastic.jpeg';
import c10 from '../../img/our clients/pharma.jpg';
import c11 from '../../img/our clients/gas.jpg';
import c12 from '../../img/our clients/knit.jpg';
import c13 from '../../img/our clients/medical.jpg';
import c14 from '../../img/our clients/oil.jpg';
import c15 from '../../img/our clients/steel.jpg';
import cer1 from '../../img/Certifications/IEIL ISO-14001-2015_page-0001.jpg';
import cer2 from '../../img/Certifications/IEIL ISO-45001-2018_page-0001.jpg';
import cer3 from '../../img/Certifications/IEIL ISO-9001-2015_page-0001.jpg';
import file1 from '../../img/Certifications/IEIL ISO-14001-2015.pdf';
import file2 from '../../img/Certifications/IEIL ISO-45001-2018.pdf';
import file3 from '../../img/Certifications/IEIL ISO-9001-2015.pdf';
import con from '../../img/contact_us.png';
import emailjs from 'emailjs-com';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFileDownload} from '@fortawesome/free-solid-svg-icons'

const element = <FontAwesomeIcon icon={faFileDownload} />

const Home = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_nt0hugd', 'template_ptvu8le', form.current, 'XJpWRCDiY7ZXDqMbc')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset();
         };

    return (
        <div>
            <Header></Header>
           <div className='row mt-5 px-lg-5 padding-left'>
               <div className='col-lg-6 col-sm-12'>
                    <h2 className='heading'>Innocuous Energy International Limited</h2>
                    <hr></hr>
                    <h5>
                    A leading Company who engages in the provision of energy & power businesses. It operates through the following segments: Liquid Pipelines, Gas Distribution & Storage, Gas Transmission & Midstream, Renewable Power Generation, and Energy Services. The Liquids Pipelines segment consists of common carrier and contract crude oil, natural gas, gas liquids and refined products pipelines and terminals.  We evaluate energy programs and plans so that future performance can be enhanced. We support our clients’ sustainable energy goals through end-to-end strategic, analytical and technical assistance. We assess energy efficiency and renewable energy opportunities and analyze their costs, benefits, and risks. We design leading programs, plans and policies that can realistically meet end-user’s requirements for future energy security and sustainability. The Company is operating its business operations in the international arena specially in USA, Canada, Europe, Turkey, Middle-Est and Asia Pacific regions.<br></br>  
                    </h5>
                    <NavLink to="/about" className="text-decoration-none">
                        <button className='btn btn-primary d-flex justify-content-start button mt-3 px-5 mb-5'>About Us &gt;</button>
                    </NavLink>

               </div>
               <div className='col-lg-6 col-sm-12 pt-4 mb-5'>
                    <Carousel fade>
                        <Carousel.Item>
                            {/* <div className="d-flex justify-content-start"> */}
                                <img
                                className="d-block w-100"
                                src={s1}
                                alt="First slide"
                                />
                                
                                <Carousel.Caption className="carousel px-5">
                                        <blockquote>"The best thing about the Earth is if you poke holes in it oil and gas comes out."</blockquote>
                                        <cite>-Naomi Klein</cite>
                                </Carousel.Caption>
                                
                            {/* </div> */}
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="d-flex justify-content-center">
                                <img
                                className="d-block w-100"
                                src={s2}
                                alt="Second slide"
                                />

                                <Carousel.Caption className="carousel px-5">
                                        <blockquote>"Oil is like a wild animal. Whoever captures it has it."</blockquote>
                                        <cite>-J. Paul Getty</cite>
                                </Carousel.Caption>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="d-flex justify-content-center">
                                <img
                                className="d-block w-100"
                                src={s3}
                                alt="Third slide"
                                />

                                <Carousel.Caption className="carousel px-5">
                                        <blockquote>"Natural gas is the future. It is here."</blockquote>
                                        <cite>-Bill Richardson</cite>
                                </Carousel.Caption>
                            </div>
                        </Carousel.Item>
                    </Carousel>
               </div>
           </div>
           <div id='service'>
           <h2 className='heading pt-5'>Services</h2>
                <hr className='w-25 mx-auto'></hr>
                <CardGroup className='px-5 pt-3'>
                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                        <Card.Img variant="top" className="rounded-3" src={c1} height="100%"/>
                        <Card.ImgOverlay className="text-light overlay">
                            <Card.Title className=''>
                                <h1 className="h4">Textile Industry</h1>
                            </Card.Title>
                        </Card.ImgOverlay>
                    </Card>
                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                        <Card.Img variant="top" className="rounded-3" src={c2} height="100%" />
                        <Card.ImgOverlay className="text-light overlay">
                            <Card.Title>
                                <h1 className="h4 my-auto">Heavy Industry</h1>
                            </Card.Title>
                        </Card.ImgOverlay>
                    </Card>
                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                        <Card.Img variant="top" className="rounded-3" src={c3} height="100%"/>
                        <Card.ImgOverlay className="text-light overlay">
                            <Card.Title>
                                <h1 className="h4 my-auto">FMCG</h1>
                            </Card.Title>
                        </Card.ImgOverlay>
                    </Card>
                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                        <Card.Img variant="top" className="rounded-3" src={c4} height="100%"/>
                        <Card.ImgOverlay className="text-light overlay">
                            <Card.Title>
                                <h1 className="h4 my-auto">Food & Bevarage Comapny</h1>
                            </Card.Title>
                        </Card.ImgOverlay>
                    </Card>
                </CardGroup>
                <CardGroup className='px-5'>
                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                        <Card.Img variant="top" className="rounded-3" src={c5} height="100%"/>
                        <Card.ImgOverlay className="text-light overlay">
                            <Card.Title>
                                <h1 className="h4 my-auto">Power Plant</h1>
                            </Card.Title>
                        </Card.ImgOverlay>
                    </Card>
                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                        <Card.Img variant="top" className="rounded-3" src={c6} height="100%"/>
                        <Card.ImgOverlay className="text-light overlay">
                            <Card.Title>
                                <h1 className="h4 my-auto">Glass Industries</h1>
                            </Card.Title>
                        </Card.ImgOverlay>
                    </Card>
                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                        <Card.Img variant="top" className="rounded-3" src={c7} height="100%"/>
                        <Card.ImgOverlay className="text-light overlay">
                            <Card.Title>
                                <h1 className="h4 my-auto">Ceramic Industries</h1>
                            </Card.Title>
                        </Card.ImgOverlay>
                    </Card>
                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                        <Card.Img variant="top" className="rounded-3" src={c8} height="100%"/>
                        <Card.ImgOverlay className="text-light overlay">
                            <Card.Title>
                                <h1 className="h4 my-auto">Cement Factory</h1>
                            </Card.Title>
                        </Card.ImgOverlay>
                    </Card>
                </CardGroup>
                <CardGroup className='px-5'>
                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                        <Card.Img variant="top" className="rounded-3" src={c9} height="100%"/>
                        <Card.ImgOverlay className="text-light overlay">
                            <Card.Title>
                                <h1 className="h4 my-auto">Plastic Industries</h1>
                            </Card.Title>
                        </Card.ImgOverlay>
                    </Card>
                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                        <Card.Img variant="top" className="rounded-3" src={c10} height="100%"/>
                        <Card.ImgOverlay className="text-light overlay">
                            <Card.Title>
                                <h1 className="h4 my-auto">Pharmaceuticals</h1>
                            </Card.Title>
                        </Card.ImgOverlay>
                    </Card>
                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                        <Card.Img variant="top" className="rounded-3" src={c11} height="100%"/>
                        <Card.ImgOverlay className="text-light overlay">
                            <Card.Title>
                                <h1 className="h4 my-auto">Gas Industries</h1>
                            </Card.Title>
                        </Card.ImgOverlay>
                    </Card>
                    <Card className="mx-3 mb-5 bg-transparent border-0 service">
                        <Card.Img variant="top" className="rounded-3" src={c12} height="100%"/>
                        <Card.ImgOverlay className="text-light overlay">
                            <Card.Title>
                                <h1 className="h4 my-auto">Knit & Composite Industries</h1>
                            </Card.Title>
                        </Card.ImgOverlay>
                    </Card>
            </CardGroup>
            <div className='row ps-5 pe-lg-3 small'>
                <div className='col-lg-6 col-sm-12'>
                    <CardGroup className=''>
                        <Card className="mx-3 mb-5 bg-transparent border-0 service">
                            <Card.Img variant="top" className="rounded-3" src={c13} height="100%"/>
                            <Card.ImgOverlay className="text-light overlay">
                                <Card.Title>
                                    <h1 className="h4 my-auto">Medicals & Hospitals</h1>
                                </Card.Title>
                            </Card.ImgOverlay>
                        </Card>
                        <Card className="mx-3 mb-5 bg-transparent border-0 service">
                            <Card.Img variant="top" className="rounded-3" src={c14} height="100%"/>
                            <Card.ImgOverlay className="text-light overlay">
                                <Card.Title>
                                    <h1 className="h4 my-auto">Oil Industries</h1>
                                </Card.Title>
                            </Card.ImgOverlay>
                        </Card>
                    </CardGroup>
                </div>
                <div className='col-lg-3 col-sm-12 padding-start pe-lg-4'>
                    <CardGroup className=''>
                        <Card className="me-3 mb-5 bg-transparent border-0 service">
                            <Card.Img variant="top" className="rounded-3" src={c15} height="100%"/>
                            <Card.ImgOverlay className="text-light overlay">
                                <Card.Title>
                                    <h1 className="h4 my-auto">Steel Industries</h1>
                                </Card.Title>
                            </Card.ImgOverlay>
                        </Card>
                    </CardGroup>
                </div>
            </div>
           </div>
           <div id='certificate'>
           <h2 className='heading pt-5'>Certifications</h2>
            <hr className='w-25 mx-auto'></hr>
            <CardGroup className='px-5 pt-3'>
                    <Card className="mx-3 mb-5 border-0 service1">
                        <Card.Img variant="top" className="rounded-3" src={cer1} height="100%"/>
                        <Card.ImgOverlay className="text-light overlay1">
                            <Card.Title className=''>
                                <NavLink to={file1} target="_blank" download>
                                <button className="btn btn-warning border-0">Download Certificate {element}</button>
                                </NavLink>
                            </Card.Title>
                        </Card.ImgOverlay>
                    </Card>
                    <Card className="mx-3 mb-5 border-0 service1">
                        <Card.Img variant="top" className="rounded-3" src={cer2} height="100%"/>
                        <Card.ImgOverlay className="text-light overlay1">
                            <Card.Title className=''>
                                <NavLink to={file2} target="_blank" download>
                                <button className="btn btn-warning border-0">Download Certificate {element}</button>
                                </NavLink>
                            </Card.Title>
                        </Card.ImgOverlay>
                    </Card>
                    <Card className="mx-3 mb-5 border-0 service1">
                        <Card.Img variant="top" className="rounded-3" src={cer3} height="100%"/>
                        <Card.ImgOverlay className="text-light overlay1">
                            <Card.Title className=''>
                                <NavLink to={file3} target="_blank" download>
                                <button className="btn btn-warning border-0">Download Certificate {element}</button>
                                </NavLink>
                            </Card.Title>
                        </Card.ImgOverlay>
                    </Card>
                </CardGroup>
           </div>
            

                <div id="contact" className="px-5 pt-5">
                    <div className="row border mb-5 mx-lg-4">
                        <div className="col-lg-5 col-md-4 col-sm-12 text-dark contact">
                            <img src={con} alt="" width="100%"/>
                        </div>
                        <div className="col-lg-7 col-md-8 col-sm-12 text-secondary mt-5 pe-lg-5">
                            <h2 className="heading">Send us a message</h2>
                            <hr className="w-50 mx-auto text-dark"></hr>
                            <div className="form text-light">
                                <form ref={form} onSubmit={sendEmail} className="w-100">
                                    <input type="text" name="name" placeholder="Your Name" className="text-dark h4"/>
                                    <input type="email" name="email" placeholder="Your Email" className="text-dark h4"/>
                                    <textarea name="message" placeholder="Write your message here" className="text-dark h4" style={{height: "90px"}}/>
                                    <input className="btn btn-primary text-light w-75 btn-lg mt-3" style={{backgroundColor: "rgb(40, 78, 150)"}}type="submit" value="Send Message" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
        </div>


    );
};

export default Home;