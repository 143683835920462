import React from 'react';
import './Inverter.css';
import Menu from '../../Menu/Menu';
import { Card, CardGroup, Table } from 'react-bootstrap';


const Inverter = () => {
    return (
        <div className='mb-5'>
            
            <div className='about4'>
            <div className='bg'>
                <Menu></Menu>
                <h1 className='text-white display-5 fw-bold doris pt-lg-5 mt-lg-5 pb-5 mx-auto top'>i-Inverter</h1>
            </div>
        </div>
            <h2 className='heading pt-5'>i-Inverter</h2>
            <hr className='w-75 mx-auto'></hr>
            <h5 className='px-5 mx-lg-3'>
                <ol>
                    <li>
                        i-Inverter-M-1000-095140, Capacity 1KW
                        <ul>
                            <li>Operate in UPS mode. </li>
                            <li>Intelligent auto reset. Over voltage protection with auto reset </li>
                            <li>Load level indication. Highly stable, crystal controller frequency. </li>
                            <li>Intelligent battery management. </li>
                            <li>Overload early warning. Over load, short circuit, lighting and battery over drain protection. </li>
                            <li>Pure sine wave. </li>
                            <li>Charger status indicator. Battery low early warning. </li>
                            <li>Reverse polarity protection. </li>
                        </ul><br></br>
                        Voltage Input: 110VDC(95VDC-140VDC) <br></br><br></br>
                        Inverter Output Voltage: 220-231V AC(RMS), Single Phase <br></br><br></br>
                        Inverter Output Power: 1KW <br></br><br></br><br></br>

                    </li>
                    <li>i-Inverter-M-1500-095140, Capacity 1.5KW
                        <ul>
                            <li>Operate in UPS mode. </li>
                            <li>Intelligent auto reset. Over voltage protection with auto reset </li>
                            <li>Load level indication. Highly stable, crystal controller frequency.</li>
                            <li>Intelligent battery management. </li>
                            <li>Overload early warning. Over load, short circuit, lighting and battery over drain protection. </li>
                            <li>Pure sine wave. </li>
                            <li>Charger status indicator. Battery low early warning. </li>
                            <li>Reverse polarity protection. </li>
                        </ul><br></br>
                        Voltage Input: 110VDC(95VDC-140VDC) <br></br><br></br>
                        Inverter Output Voltage: 220-231V AC(RMS), Single Phase <br></br><br></br>
                        Inverter Output Power: 1.5KW <br></br><br></br>

                    </li>
                </ol>
            </h5>
            
            
        </div>
    );
};

export default Inverter;